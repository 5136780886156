import React from 'react';
import PropTypes from 'prop-types';
import { Container, Image } from 'react-bootstrap';
import LazyLoad from 'react-lazyload';
import Pains from '../images/pains.gif';
import TitleWithBg from './TitleWithBg';

const PainItem = ({ title, main, description, iconId, id }) => {
  return (
    <div className={`pain-item ${!main ? 'no-main-text' : ''}`}>
      <Image src={iconId} alt={id} className="pain-icon" />
      <div className="pain-title-wrapper">
        <h4 className="pain-title">{title}</h4>
        <h4 className="pain-main">{main}</h4>
      </div>
      <p className="pain-description">{description}</p>
    </div>
  );
};

PainItem.propTypes = {
  title: PropTypes.string,
  main: PropTypes.string,
  description: PropTypes.string,
  iconId: PropTypes.string,
  id: PropTypes.string,
};

function PainsAndNeeds({ data = [] }) {
  return (
    <div className="pains-needs-wrapper">
      <LazyLoad>
        <Container>
          <TitleWithBg title="业务痛点及需求" />
          <div className="pains-list">
            {data.map((item) => (
              <PainItem {...item} key={item.id} />
            ))}
          </div>
          <Image className="pains-gif" src={Pains} alt="pains" />
        </Container>
      </LazyLoad>
    </div>
  );
}

PainsAndNeeds.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
      main: PropTypes.string,
      description: PropTypes.string,
      iconId: PropTypes.string,
    })
  ),
};

export default PainsAndNeeds;
