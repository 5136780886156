import React from 'react';
import Banner2 from '../components/Banner2';
import PrivacyImageTextList from '../components/PrivacyImageTextList';
import Layout from '../components/Layout';
import CasesBanner from '../images/scenes-banner.svg';
import SceneSolutions from '../components/SceneSolutions';
import Pain4 from '../images/pain-4.svg';
import Pain5 from '../images/pain-5.svg';
import Pain6 from '../images/pain-6.svg';
import PainsAndNeeds from '../components/PainsAndNeeds';
import SceneSolutionsImg2 from '../images/scenes-solutions-2.png';

const Ptitle3 =
  '将全渠道用户统一至微信生态进行流量打造，用户进行培育及再利用，\n' + '实现低成本获客转化';

const VALUE_DATA = [
  {
    id: 'value-1',
    title: '公域流量红利丧尽',
    main: '',
    description: '流量红利时代结束，广告获客成本逐年增加，转化率越来越差',
    iconId: Pain4,
  },
  {
    id: 'value-2',
    title: '线索缺乏维护再利用',
    main: '',
    description: '大量线索沉寂流失，销售跟进后没有有效手段长期维护客户',
    iconId: Pain5,
  },
  {
    id: 'value-3',
    title: '用户画像单薄',
    main: '',
    description: '传统触点互动费用高频次低，导致客户行为和画像收集困难',
    iconId: Pain6,
  },
];

export default () => (
  <Layout current="scenes" subCurrent="privacy">
    <Banner2
      title="私域流量运营"
      Ptitle3={Ptitle3}
      backgroundImage={CasesBanner}
      showDemoBtn
      buttonText="体验场景DEMO"
    />
    <PainsAndNeeds data={VALUE_DATA} />
    <SceneSolutions src={SceneSolutionsImg2} />
    <PrivacyImageTextList />
  </Layout>
);
