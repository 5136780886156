import React from 'react';
import PropTypes from 'prop-types';
import { Container } from 'react-bootstrap';
import ApplyButton from './ApplyButton';

function Banner2({
  title,
  titleClassName = '',
  title2,
  backgroundImage,
  Ptitle3,
  ptitleClassName = '',
  showDemoBtn,
  buttonText,
  rightRender,
}) {
  return (
    <section
      className={`banner2 ${rightRender ? 'has-right' : ''}`}
      onFocus={() => {}}
      onMouseEnter={(e) => {
        const wrap = document.querySelector('.banner2');
        // wrap.addEventListener('onmovseleaver', onMouseLeaveHandle ,false)
        wrap.style.transition = 'all 3s linear';
        const w = wrap.offsetWidth;
        const h = wrap.offsetHeight;
        const x = (e.clientX - wrap.offsetLeft - w / 2) * (w > h ? h / w : 1);
        const y = (e.clientY - wrap.offsetTop - h / 2) * (h > w ? w / h : 1);
        // 上(0) 右(1) 下(2) 左(3)
        const direction = Math.round((Math.atan2(y, x) * (180 / Math.PI) + 180) / 90 + 3) % 4;
        switch (direction) {
          // 上方
          case 0:
            wrap.style.backgroundPositionY = 'bottom center';
            break;
          // 右侧
          case 1:
            wrap.style.backgroundPosition = 'center -20px';
            break;
          // 下方
          case 2:
            wrap.style.backgroundPosition = 'top center';
            break;
          // 左侧
          case 3:
            wrap.style.backgroundPosition = 'center 20px';
            break;
          default:
            break;
        }
      }}
      onMouseLeave={() => {
        const wrap = document.querySelector('.banner2');
        wrap.style.backgroundPosition = 'center center';
        // wrap.style.transition='none';
      }}
      style={{ backgroundImage: `url(${backgroundImage})`, backgroundPosition: 'center center' }}
    >
      <Container>
        <h1 className={`title1 ${titleClassName}`}>{title}</h1>
        {title2 && <h1 className="title2">{title2}</h1>}
        {Ptitle3 && <p className={ptitleClassName}>{Ptitle3}</p>}
        {showDemoBtn && <ApplyButton size="small">{buttonText || '体验行业DEMO'}</ApplyButton>}
      </Container>
      {rightRender}
    </section>
  );
}

Banner2.propTypes = {
  title: PropTypes.string,
  titleClassName: PropTypes.string,
  title2: PropTypes.string,
  backgroundImage: PropTypes.string,
  Ptitle3: PropTypes.string,
  ptitleClassName: PropTypes.string,
  showDemoBtn: PropTypes.bool,
  buttonText: PropTypes.string,
  rightRender: PropTypes.node,
};

export default Banner2;
