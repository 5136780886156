import React from 'react';
import ImageText from './ImageText';
import Privacy1 from '../images/privacy-1.svg';
import Privacy2 from '../images/privacy-2.svg';
import Privacy3 from '../images/privacy-3.svg';
import Privacy4 from '../images/privacy-4.svg';

export default () => {
  return (
    <section id="channels" className="channels-list" style={{ padding: 0 }}>
      <ImageText
        image={Privacy1}
        title="全渠道引流获客"
        description="过去企业普遍以表单留资方式获客，当完成“客户留资—销售跟进”流程时，线索已流失80%以上。现在PC端，通过引导客户关注公众号或添加企业微信后，荟聚可将其网站访问行为与微信身份成功绑定，为你打开“上帝视角”，每一个客户的在线行为轨迹清晰呈现；移动端，智能表单支持直接投放基于企业微信的渠道活码，从广告直达企业微信，大大缩短获客路径。"
        imageSize={6}
        position="right"
        bgColor="yellow"
        showDemoBtn
      />
      <ImageText
        image={Privacy2}
        title="私域分层运营"
        description="私域不只是将用户导向微信公众号&企业微信，更强调的是用户分层和数据精细化运营。根据客户的属性特征、行为轨迹，动态描绘客户画像，制定个性化、场景化的营销策略，提升营销转化。让企业在全渠道和客户进行实时、个性化的持续互动，建立立体式营销体系覆盖客户的全生命周期。"
        imageSize={6}
        bgColor="white"
        showDemoBtn
      />
      <ImageText
        image={Privacy3}
        title="多场景营销互动"
        description="提供可视化营销内容生产工具，针对微信、企业微信等社交生态下营销触点进行一站式集中管理和自动化营销赋能，如文档、直播、微官网、落地页、H5、视频、邮件、短信、小程序等，满足B2B企业的多元化内容营销需求，无需技术投入，一键建立企业多渠道内容矩阵，通过内容营销吸引潜在客户并持续获客。"
        imageSize={6}
        position="right"
        bgColor="yellow"
        showDemoBtn
      />
      <ImageText
        image={Privacy4}
        title="企业微信互动雷达"
        description="优质的私域除了可以主动触达/唤醒客户，更重要的是能够实时监测用户行为，通过客户动态&属性特征，类AI适时提醒SDR/销售及时跟进（比如某某行业，公司规模，浏览时长大于X分钟，同一篇内容的观看次数大于X次，分享次数大于X次等），辅助SDR/销售提供大规模个性化服务。"
        imageSize={6}
        bgColor="white"
        showDemoBtn
      />
    </section>
  );
};
