import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Fade from 'react-reveal/Fade';
import { Container, Image, Row, Col } from 'react-bootstrap';
import ApplyButton from './ApplyButton';

function ImageText({
  position = 'left',
  bgColor = 'white',
  imageSize = 6,
  image,
  title = '',
  description = '',
  descriptionList = [],
  url = '',
  showDemoBtn = false,
  demoBtnText = '',
  className = '',
}) {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (window.innerWidth > 769) {
      setIsMobile(false);
    } else {
      setIsMobile(true);
    }
  }, []);

  return (
    <section className={`image-text ${bgColor} ${className}`}>
      <Container>
        <Row>
          <Col
            // lg={{ span: imageSize, order: position === 'left' ? 1 : 2 }}
            xl={{ span: imageSize, order: position === 'left' ? 1 : 2 }}
            lg={{ span: 12, order: 2 }}
            md={{ span: 12, order: 2 }}
            sm={{ span: 12, order: 2 }}
            xs={{ span: 12, order: 2 }}
            className="image"
          >
            <Fade
              left={!isMobile && position === 'left'}
              right={!isMobile && position !== 'left'}
              bottom={isMobile}
              duration={600}
              delay={200}
              distance="30px"
            >
              {React.isValidElement(image) ? image : <Image src={image} className="img-fluid" />}
            </Fade>
          </Col>
          <Col
            // lg={{ span: 12 - imageSize, order: position === 'left' ? 2 : 1 }}
            xl={{ span: 12 - imageSize, order: position === 'left' ? 2 : 1 }}
            lg={{ span: 12, order: 1 }}
            md={{ span: 12, order: 1 }}
            sm={{ span: 12, order: 1 }}
            xs={{ span: 12, order: 1 }}
            className="content"
          >
            <Fade
              left={!isMobile && position !== 'left'}
              right={!isMobile && position === 'left'}
              bottom={isMobile}
              duration={1000}
              delay={200}
              distance="30px"
            >
              <div>
                {React.isValidElement(title) ? title : <h3>{title}</h3>}
                {descriptionList.length ? (
                  descriptionList.map((text) => <p key={text}>{text}</p>)
                ) : (
                  <p>{description}</p>
                )}
                {url && (
                  <a href={url} target="_blank" rel="noreferrer">
                    查看详情&nbsp;&gt;
                  </a>
                )}
                {showDemoBtn && <ApplyButton size="small">{demoBtnText || '免费体验DEMO'}</ApplyButton>}
              </div>
            </Fade>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

ImageText.propTypes = {
  position: PropTypes.oneOf(['left', 'right']),
  bgColor: PropTypes.oneOf(['yellow', 'white', 'grey']),
  image: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  description: PropTypes.string,
  descriptionList: PropTypes.arrayOf(PropTypes.string),
  imageSize: PropTypes.oneOf([5, 6]),
  url: PropTypes.string,
  showDemoBtn: PropTypes.bool,
  demoBtnText: PropTypes.string,
  className: PropTypes.string,
};

export default ImageText;
