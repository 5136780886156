import React from 'react';
import PropTypes from 'prop-types';
import { Container, Image } from 'react-bootstrap';
import LazyLoad from 'react-lazyload';
import TitleWithBg from './TitleWithBg';

function SceneSolutions({ src }) {
  return (
    <div id="scene-solutions" className="scene-solutions">
      <Container>
        <TitleWithBg title="解决方案" />
        <LazyLoad>
          <div>
            <Image src={src} alt="SceneSolutionsImg" />
          </div>
        </LazyLoad>
        {/*<Row>*/}
        {/*  <Col span={4}>*/}
        {/*    <FlowItem {...SOLUTIONS_DATA.item_1} />*/}
        {/*  </Col>*/}
        {/*  <Col span={4}>*/}
        {/*    <FlowItem {...SOLUTIONS_DATA.item_2} />*/}
        {/*  </Col>*/}
        {/*  <Col span={4}>*/}
        {/*    <FlowItem {...SOLUTIONS_DATA.item_3} />*/}
        {/*  </Col>*/}
        {/*</Row>*/}
        {/*<Row>*/}
        {/*  <Image src={SolutionsFlow} className="img-fluid" />*/}
        {/*</Row>*/}
        {/*<Row>*/}
        {/*  <Col span={4}>*/}
        {/*    占位*/}
        {/*  </Col>*/}
        {/*  <Col span={4}>*/}
        {/*    <FlowItem {...SOLUTIONS_DATA.item_4} />*/}
        {/*  </Col>*/}
        {/*  <Col span={4}>*/}
        {/*    <FlowItem {...SOLUTIONS_DATA.item_5} />*/}
        {/*  </Col>*/}
        {/*</Row>*/}
      </Container>
    </div>
  );
}

SceneSolutions.propTypes = {
  src: PropTypes.string,
};

export default SceneSolutions;
