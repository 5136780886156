import React from 'react';
import PropTypes from 'prop-types';

function TitleWithBg({ title, subTitle }) {
  return (
    <div className="title-wrapper title-bg">
      {title && (
        <>
          <div className="background-bar" />
          <h3 className={subTitle ? 'has-subtitle' : ''}>{title}</h3>
        </>
      )}
      {subTitle && <h4>{subTitle}</h4>}
    </div>
  );
}

TitleWithBg.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
};

export default TitleWithBg;
